// Variables

$black:  var(--body-font-color);
$white: rgba(187, 166, 154, 0.85);
$gray: var(--body-font-color);

$text-arrow-space: 16px;
$shaft-width: 64px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.the-arrow {
  width: $shaft-width;
  transition: all 0.2s;
 
  &.-left {
    position: absolute;
    top: 60%;
    left: 0;

    .shaft {
      width: 0;
      background-color: $gray;
      
      &:before,
      &:after {
        width: 0;
        background-color: $gray;
      }

      &:before {
        transform: rotate(0);
      }

      &:after {
        transform: rotate(0);
      }
    }
  }
  
  &.-right {
    top: 3px;
    
    .shaft {
      width: $shaft-width;
      transition-delay: 0.2s;

      &:before,
      &:after { 
        width: $arrow-head-width;
        transition-delay: 0.3s;
        transition: all 0.5s;
      }
      
      &:before {
        transform: rotate(40deg);
      }
      
      &:after {
        transform: rotate(-40deg);
      }
    }
  }

  .shaft {
    background-color: $gray;
    display: block;
    height: $shaft-thickness;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;

    &:before,
    &:after {
      background-color: $gray;
      content: '';
      display: block;
      height: $arrow-head-thickness;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s;
      transition-delay: 0;
    }

    &:before {
      transform-origin: top right;
    }

    &:after {
      transform-origin: bottom right;
    }
  }
}

.animated-arrow {
  display: inline-block;
  color: $gray;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
  
  &:hover {
    color: $white;
    
    .the-arrow.-left {
      .shaft {
        width: $shaft-width;
        transition-delay: 0.1s;
        background-color: $white;

        &:before,
        &:after {
          width: $arrow-head-width;
          transition-delay: 0.1s;
          background-color: $white;
        }

        &:before {
          transform: rotate(40deg);
        }

        &:after {
          transform: rotate(-40deg);
        }
      }
    }
    
    .main {
      transform: translateX($shaft-width + $text-arrow-space);
      
      .the-arrow.-right {
        .shaft {
          width: 0;
          transform: translateX(200%);
          transition-delay: 0;
          
          &:before,
          &:after {
            width: 0;
            transition-delay: 0;
            transition: all 0.1s;
          }

          &:before {
            transform: rotate(0);
          }

          &:after {
            transform: rotate(0);
          }
        }
      }
    }
  }
  
  .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    
    .text {
      margin: 0 $text-arrow-space 0 0;
      line-height: 1;
      text-transform: uppercase;
    }
    
    .the-arrow {
      position: relative;
    }
  }
}
