.ui.left.sidebar {
  padding-top: 90px !important;
}

.ui.text.menu .item {
  height: 4.8rem !important;
  line-height: 3.4rem !important;
  display: block !important;
  white-space: nowrap !important;
  font-size: 1.8rem !important;
  font-family: var(--body-font) !important;
  text-transform: uppercase !important;
  transition:0.5s ease all; 
  -moz-transition:0.5s ease all; 
  -webkit-transition:0.5s ease all;
}

.text-white {
  color: #fff !important;
}

.ui.text.menu .item:hover {
  color: var(--secondary-font-color) !important;
  transition:0.5s ease all; 
  -moz-transition:0.5s ease all; 
  -webkit-transition:0.5s ease all;
}

.ui.menu .active.item:after {
  content: '' !important;
  position: absolute !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  top: -.1rem !important;
  height: 100% !important;
  width: 9rem !important;
  border-top: .1rem solid currentColor !important;
  border-bottom: .1rem solid currentColor !important;
  transition:0.5s ease all; 
  -moz-transition:0.5s ease all; 
  -webkit-transition:0.5s ease all;
}

.ui.menu .item:hover::after {
  content: '' !important;
  position: absolute !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  top: -.1rem !important;
  height: 100% !important;
  width: 9rem !important;
  border-top: .1rem solid currentColor !important;
  border-bottom: .1rem solid currentColor !important;
  cursor: pointer;
}

.ui.menu .item {
  text-transform: uppercase !important;
  font-size: 1.3rem !important;
  font-family: var(--body-font) !important;
}

.ui.inverted.menu .item:before {
  background: transparent !important;
}