.notification-push-cart {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 99;
}

.content-headline {
  font-size: 8.5rem;
  font-family: var(--secondary-font);
  color: var(--secondary-font-color);
  font-weight: 100;
  opacity: 0;
  animation: fadeUp .5s forwards;
  animation-delay: .5s;
}