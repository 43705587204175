.block-content.special {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 30px 50px 50px 50px !important;
  color: #ffffff;
  overflow: visible;
}
.block-special {
  cursor: pointer;
  position: relative;
}
.block-special:hover,
.block-special.active {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  box-shadow: 0 0 10px var(--secondary-font-color);
  -webkit-box-shadow: 0 0 10px var(--secondary-font-color);
  -moz-box-shadow: 0 0 10px var(--secondary-font-color);
  -ms-box-shadow: 0 0 10px var(--secondary-font-color);
  -o-box-shadow: 0 0 10px var(--secondary-font-color);
}

.block-special:hover .block-content.special.sp-1:after,
.block-special.active .block-content.special.sp-1:after {
  background: var(--secondary-font-color);
}
.block-special:hover .block-content.special.sp-0:after,
.block-special.active .block-content.special.sp-0:after {
  background: var(--secondary-font-color);
}
.block-content.special.sp-1 {
  background: var(--secondary-font-color);
}
.block-content.special.sp-0 {
  background: var(--secondary-font-color);
}
.block-content.special.sp-1 {
  top: 0;
}
.block-content.special.sp-0 {
  bottom: 0;
}
.block-content.special:after {
  content: "";
  width: 100%;
  padding: 8px 0;
  position: absolute;
  left: 0;
}
.block-content.special.sp-1:after {
  background: rgba(187, 166, 154, .85);
  bottom: -15px;
}
.block-content.special.sp-0:after {
  background: rgba(187, 166, 154, .85);
  top: -15px;
}
.block-price.special {
  color: #ffffff;
  position: absolute;
  display: contents;
  right: 50px;
  top: 40px;
}
.block-img.special {
  height: 415px;
}
.special.sp-1 h2 {
  color: #ffffff;
}
.special.sp-0 h2 {
  color: #ffffff;
}

.background-img {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: #353031;
  background-image: none;
  background-origin: padding-box;
  background-position: 50% 50% !important;
  background-repeat: repeat;
  background-size: cover !important;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.background-img {
  width: 100%;
  height: 100%;
}