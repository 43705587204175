.ui.items>.item>.content>.header {
  font-family: var(--body-font) !important;
}

.ui.items>.item>.content>.description {
  font-family: var(--body-font);
}

.color-white {
  color: #fff !important;
}

.click-enable:hover {
  cursor: pointer;
}