.laoderSpinner {
  z-index: 10000;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 400px;
}

.loaderCover {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: #000000;
  z-index:9999;
}