.ui.text.menu .item {
  height: 4.8rem !important;
  line-height: 3.4rem !important;
  display: block !important;
  white-space: nowrap !important;
  font-size: 2rem !important;
  font-family: var(--body-font) !important;
  text-transform: uppercase !important;
  transition:0.5s ease all; 
  -moz-transition:0.5s ease all; 
  -webkit-transition:0.5s ease all;
}

.ui.text.menu .item:hover {
  color: var(--secondary-font-color) !important;
  transition:0.5s ease all; 
  -moz-transition:0.5s ease all; 
  -webkit-transition:0.5s ease all;
}

.ui.menu .active.item:after {
  content: '' !important;
  position: absolute !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  top: -.1rem !important;
  height: 100% !important;
  width: 9rem !important;
  border-top: .1rem solid currentColor !important;
  border-bottom: .1rem solid currentColor !important;
  transition:0.5s ease all; 
  -moz-transition:0.5s ease all; 
  -webkit-transition:0.5s ease all;
}

.ui.menu .item:hover::after {
  content: '' !important;
  position: absolute !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  top: -.1rem !important;
  height: 100% !important;
  width: 9rem !important;
  border-top: .1rem solid currentColor !important;
  border-bottom: .1rem solid currentColor !important;
  cursor: pointer;
  visibility: initial !important;
  border-right: 0 !important;
}

.ui.menu .item {
  text-transform: uppercase !important;
  font-size: 1.3rem !important;
  font-family: var(--body-font) !important;
}

.ui.inverted.menu .item:before {
  background: transparent !important;
}

.block-cat h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 400;
  text-transform: none;
  color: var(--secondary-font-color);
}

.block-cat h2, h1.large {
  font-family: var(--secondary-font);
}

.block-content {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.block-header {
  font-family: var(--body-font);
  color: var(--body-font-color);
  font-size: 2.4rem;
}

.block-price {
  color: var(--secondary-font-color);
  font-weight: 700;
  text-align: right;
  display: block;
  font-size: 15px;
  margin-top: 10px;
}

.food-image {
  height: 30px;
  width: auto;
  vertical-align: bottom;
  margin-left: 10px;
  margin-right: 10px;
}

.dots {
  background-image: radial-gradient(circle closest-side, var(--secondary-font-color) 80%, #fff 10%);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: 4px 2px;
  border: 0 none;
  display: block;
  height: 3px;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
}

.ui.toggle.checkbox .box, .ui.toggle.checkbox label {
  color: #fff !important;
}

.ui.toggle.checkbox input:checked~.box, .ui.toggle.checkbox input:checked~label {
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .ui.text.menu .item {
    flex-direction: column;
  }
}