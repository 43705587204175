*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font: 'Source Sans Pro', sans-serif;
    --secondary-font:'Alex Brush';
    --body-font: "Benton Sans Comp Book",'Open Sans Condensed',sans-serif;
    --main-font-color-dark: #252525;
    --secondary-font-color: #3c1f00;
    --body-font-color: #430e10;
    --main-font-color-error: #7F1019;
}

i.body.icon {
  color: #430e10 !important;
}

i.secondary.icon {
  color: #3c1f00 !important;
}

input, select, textarea {
  font-family: var(--body-font) !important;
  color: var(--body-font-color) !important;
  line-height: 1.15 !important;
  font-size: 1.8rem !important;
  margin: 0 !important;
}

b, strong {
  font-weight: bold !important;
}

html {
    font-family: var(--body-font) !important;
    font-size: 10px !important;
    color: var(--body-font-color) !important;
    scroll-behavior: smooth !important;
}

body {
  margin: 0 !important;
  font-family: var(--body-font) !important;
  font-size: 1.5rem !important;
  color: #000 !important;
  line-height: 1.25 !important;
  font-weight: 200 !important;
  overflow-x: hidden !important;
}

section {
    padding: 3.9rem 0;
    overflow: hidden;
}

img {
    width: 100%;
    max-width: 100%;
}

a {
  text-decoration: none;
  color: var(--secondary-font-color);
}

a:hover {
  cursor: pointer;
  color: var(--secondary-font-color);
}

p {
  font-size: 1.9rem;
}

.container {
  width: 100%;
  max-width: 122.5rem;
  margin: 0 auto;
}

.mb-40 {
  margin-bottom: 40px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.mt-40 {
  margin-top: 40px !important;
}


/* Text color */

.text-white {
  color: #fff !important;
}

.text-secondary {
  color: var(--secondary-font-color) !important;
}

.text-main {
  color: var(--main-font-color-dark) !important;
}

.text-body {
  color: var(--body-font-color) !important;
}

.text-center {
  display: flex;
  justify-content: center;
}

/* Button styles */
.button {
  font-family: var(--body-font);
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  width: 13.5rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  height: 4.8rem;
  line-height: 2.4rem;
  padding: 0 1.5rem 0 0;
  color: var(--body-font-color);
  text-align: left;
}

.button.btn-large {
  width: fit-content;
  font-size: 3.8rem;
}

.button.btn-white::after {
  background-image: url(assets/arrows/arrow-right-white.svg);
}
.button-arrow::after {
  content: "";
  width: 1.5rem;
  height: 4.8rem;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(assets/arrows/arrow-right-highlight.svg) center center no-repeat;
  -webkit-transition: right .5s ease;
  transition: right .5s ease;
}

.button-row {
  text-align: right !important;
}

/* Header styles */
header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(0,0,0, 3.5), transparent);
    padding-top: 40px;
    padding-bottom: 40px;
}

.nav {
    height: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-toggle {
    color: #fff;
    font-size: 2.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5rem;
    cursor: pointer;
    z-index: 1500;
}

.fa-times {
    display: none;
}

.nav-list {
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    background-color: var(--main-font-color-dark);
    padding: 4.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1250;
    transform: translateX(-100%);
    transition: transform .5s;
}

.nav::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;
    opacity: 0;
    transform: scale(0);
    transition: opacity .5s;
}

.open .fa-times {
    display: block;
}

.open .fa-bars {
    display: none;
}

.open .nav-list {
    transform: translateX(0);
}

.open .nav::before {
    opacity: 1;
    transform: scale(1);
}

.nav-item {
    border-bottom: 2px solid rgba(255,255,255, .3);
}

.nav-link {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-right: -2px;
    transition: color .5s;
}

.nav-link:hover {
    color: var(--secondary-font-color);
}

.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.sub-headline {
    font-size: 6.5rem;
    font-family: var(--secondary-font);
    color: var(--secondary-font-color);
    font-weight: 100;
    line-height: .4;
    letter-spacing: 2px;
    opacity: 0;
    animation: fadeUp .5s forwards;
    animation-delay: .5s;
}

.first-letter {
    text-transform: uppercase;
    font-size: 10.3rem;
}

.headline {
    color: #fff;
    font-size: 2.7rem;
    font-family: var(--main-font);
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: .5rem;
    margin-right: -.5rem;
    animation: scale .5s forwards;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    width: 100%;
    max-width: 8.4rem;
    height: .25rem;
    background-color: #fff;
    position: relative;
    opacity: 0;
    animation: grow 2s forwards;
    animation-delay: .9s;
}

.line-right::before,
.line-left::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: .6rem solid transparent;
    opacity: 0;
    animation: grow 1.5s forwards;
    animation-delay: 1.2s;
}

.line-right::before {
    border-right-color: #fff;
    right: 0;
}

.line-left::before {
    border-left-color: #fff;
    left: 0;
}

.asterisk {
    font-size: 1.2rem;
    color: var(--secondary-font-color);
    margin: 0 1.6rem;
    opacity: 0;
    animation: spin .5s forwards;
    animation-delay: .7s;
}

.single-animation {
    opacity: 0;
    animation: fadeDown .5s forwards;
    animation-delay: 1.5s;
}

.headline-description h5 {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
    letter-spacing: 3px;
    margin-right: -3px;
}

.btn {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: -2px;
}

.cta-btn {
    font-size: 1.5rem;
    background-color: #fff;
    padding: .9rem 1.8rem;
    color: var(--body-font-color);
    border-radius: .4rem;
    transition: background-color .5s;
}

.cta-btn:hover,
.cta-btn:focus {
    color: #fff;
    background-color: var(--secondary-font-color);
}

/* Discover our Story */

.global-headline {
    text-align: center;
    margin-top: 3.9rem;
}

.discover-our-story .global-headline {
    margin-top: 6.9rem;
}

.global-headline .asterisk {
    color: var(--body-font-color);
    margin: 2.4rem 0;
}

.headline-dark {
    color: var(--main-font-color-dark);
    letter-spacing: .7rem;
    margin-right: -.7rem;
}

.global-headline .sub-headline {
    letter-spacing: -1px;
    line-height: .42;
}

.restaurant-info {
    text-align: center;
}
.restaurant-right {
  text-align: right;
}
.restaurant-left {
  text-align: left;
}

.restaurant-description {
    margin-bottom: 3rem;
}

.restaurant-description p {
    margin-bottom: 2.4rem;
}

.body-btn {
    font-size: 1.5rem;
    color: var(--secondary-font-color);
    position: relative;
    transition: color .5s;
}

.body-btn::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: var(--secondary-font-color);
    transition: background-color .5s;
}

.body-btn:hover,
.body-btn:focus {
    color: var(--main-font-color-dark);
}

.body-btn:hover::before,
.body-btn:focus::before {
    background-color: var(--main-font-color-dark);
}

/* Gift card */

.gift-card {
  background: url("images/gift-card.jpg") center no-repeat;
}

/* Tasteful recipes */

.tasteful-recipes {
  background: url("images/tasteful-recipes.jpg") center no-repeat;
}

.tasteful-hero {
  background: url("images/hero.jpg") center no-repeat;
}

.between {
    min-height: 65vh;
    display: flex;
    align-items: center;
}

/* Special offers */

.special-offers {
  background: url("images/special-offers.jpg") center no-repeat;
}

/* DISCOVER OUR MENU */

.image-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
}

.discover-our-menu .restaurant-description {
    padding-top: 3rem;
    margin-bottom: 0;
}

/* PERFECT BLEND */

.perfect-blend {
  background: url("images/perfect-blend.jpg") center no-repeat;
}

.discover-menu {
  background: url("images/discover-menu.jpg") center no-repeat;
}

.hero-small {
  background: url("images/hero.jpg") center no-repeat;
  background-size: cover;
  height: 330px;
  min-height: 330px;
}

/* FOOTER */

footer {
    padding: 7.9rem 0;
    background: url("images/bg03.jpg");
    color: #ecddc9;
    text-align: center;
    position: relative;
}

.footer-links {
  font-size: 30px;
  font-family: var(--secondary-font);
  color: rgba(187, 166, 154, 0.85);
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.footer-links:hover {
  cursor: pointer;
}

.back-to-top {
    width: 7rem;
    height: 7rem;
    background-color: #2C1F0C;
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}

.back-to-top i {
    display: block;
    color: #ecddc9;
    font-size: 2rem;
    padding: 2.3rem;
    animation: up 2s infinite;
}

.footer-content {
    overflow: hidden;
}

.footer-content h4 {
    font-size: 1.9rem;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 3px;
    margin-bottom: 3rem;
}

.footer-content .asterisk {
    margin: 2.4rem 0;
}

.footer-content-about {
    margin-bottom: 5.4rem;
}

.footer-content-about p {
    line-height: 2;
}

.block-social a {
  border: 2px solid var(--secondary-font-color);
  color: var(--secondary-font-color);
  display: inline-block;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  line-height: 40px;
}

.block-social li {
  display: inline-block;
  margin-right: 12px;
  text-align: center;
}

.social-icons {
    list-style: none;
    margin-bottom: 5.4rem;
    display: flex;
    justify-content: center;
}

.social-icons i {
    font-size: 2rem;
    color: #fff;
    padding: .8rem 2rem;
    opacity: .5;
    transition: color .5s;
}

.social-icons i:hover,
.social-icons i:focus {
    color: var(--secondary-font-color);
}

.newsletter-form {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.newsletter-input {
    width: 100%;
    max-width: 25rem;
    padding: 1rem;
    border-radius: .4rem;
}

.newsletter-btn {
    background-color: transparent;
    border: none;
    color: var(--main-font-color-dark);
    cursor: pointer;
    font-size: 1.6rem;
    padding: 1px .6rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10.5rem;
}

.newsletter-section {
  background: rgba(187, 166, 154, .85);
}

/* active state */
input:not([type='checkbox']):focus ~ .highlight, 
textarea:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:var(--secondary-font-color); }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:var(--secondary-font-color); }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:var(--secondary-font-color); }
  to 	{ width:0; background:transparent; }
}

/* MEDIA QUERIES */
@media screen and (min-width: 900px) {
    section {
        padding: 7.9rem;
    }

    .menu-toggle {
        display: none;
    }

    .nav {
        justify-content: space-between;
    }

    .nav-list {
        position: initial;
        width: initial;
        height: initial;
        background-color: transparent;
        padding: 0;
        justify-content: initial;
        flex-direction: row;
        transform: initial;
        transition: initial;
    }

    .nav-item {
        margin: 0 2.4rem;
        border: none;
    }

    .nav-item:last-child {
        margin-right: 0;
    }

    .nav-link {
        font-size: 1.3rem;
    }

    .active {
        position: relative;
    }

    .active:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        //background-color: #fff;
        left: 0;
        bottom: -3px;
    }

    .sub-headline {
        font-size: 10rem;
    }

    .first-letter {
        font-size: 12.2rem;
    }

    .headline {
        font-size: 4.7rem;
        letter-spacing: .8rem;
    }

    .line {
        max-width: 11.4rem;
    }

    .restaurant-info {
        display: flex;
        align-items: center;
    }

    .restaurant-info > div {
        flex: 1;
    }

    .padding-right {
        padding-right: 7rem;
    }

    .footer-content {
        margin: auto;
    }

    .footer-content-about {
        max-width: 51.3rem;
        margin: 0 auto 5.4rem;
    }

    .footer-content-divider {
        display: flex;
        justify-content: space-between;
    }

    .social-media,
    .newsletter-form {
        width: 100%;
        margin: 0 1rem;
    }

    .social-icons i {
        opacity: 1;
    }

    .newsletter-btn {
        margin-left: 7.5rem;
    }

    .headline {
        font-size: 3.7rem;
    }

    .sub-headline {
      font-size: 8.5rem;
    }
}

body ::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 500px) {
  .scroll-enabled {
    overflow-x: scroll;
    justify-content: initial !important;
  }
}

@media (pointer: coarse) {
  .background-fixed {
    background-attachment: initial !important;
  }
}



/* ANIMATIONS */
@keyframes fadeUp {
    0% {
        transform: translateY(4rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scale {
    0% {
        transform: scale(2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes grow {
    0% {
        width: 0;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        opacity: 1;
        transform: rotate(-360deg);
    }
}

@keyframes fadeDown {
    0% {
        transform: translateY(-1rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes up {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-1rem);
    }
}

@media only screen and (min-width: 320px){
  .ui[class*="very wide"].left.sidebar, .ui[class*="very wide"].right.sidebar {
    width: 300px !important;
  }
}

@media only screen and (min-width: 350px){
  .ui[class*="very wide"].left.sidebar, .ui[class*="very wide"].right.sidebar {
    width: 330px !important;
  }
}

@media only screen and (min-width: 500px){
  .ui[class*="very wide"].left.sidebar, .ui[class*="very wide"].right.sidebar {
    width: 480px !important;
  }
}

@media only screen and (min-width: 1200px){
  .ui.container {
      width: 80% !important;
  }
}