@font-face {
  font-family: 'Alex Brush';
  src: url('AlexBrush-Regular.eot');
  src: url('AlexBrush-Regular.eot?#iefix') format('embedded-opentype'),
      url('AlexBrush-Regular.woff2') format('woff2'),
      url('AlexBrush-Regular.woff') format('woff'),
      url('AlexBrush-Regular.ttf') format('truetype'),
      url('AlexBrush-Regular.svg#AlexBrush-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}